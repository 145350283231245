@import './node_modules/@fortawesome/fontawesome-pro/scss/functions';

$fa-css-prefix          : fa;
$fa-style               : 900;

$fa-style-family        : "Font Awesome 6 Pro";
$fa-style-family-light  : "Font Awesome 6 Pro Light";
$fa-style-family-duotone: "Font Awesome 6 Duotone";
$fa-style-family-brands : "Font Awesome 6 Brands";

$fa-display             : inherit;
$fa-font-display        : inherit;
$fa-fw-width            : fa-divide(20em, 16);
$fa-inverse             : #fff;

$fa-border-color        : #eee;
$fa-border-padding      : .2em .25em .15em;
$fa-border-radius       : .1em;
$fa-border-style        : solid;
$fa-border-width        : .08em;

$fa-size-scale-2xs      : 10;
$fa-size-scale-xs       : 12;
$fa-size-scale-sm       : 14;
$fa-size-scale-base     : 16;
$fa-size-scale-lg       : 20;
$fa-size-scale-xl       : 24;
$fa-size-scale-2xl      : 32;

$fa-sizes: (
  "2xs"                 : $fa-size-scale-2xs,
  "xs"                  : $fa-size-scale-xs,
  "sm"                  : $fa-size-scale-sm,
  "lg"                  : $fa-size-scale-lg,
  "xl"                  : $fa-size-scale-xl,
  "2xl"                 : $fa-size-scale-2xl
);

$fa-li-width            : 2em;
$fa-li-margin           : $fa-li-width * fa-divide(5, 4);

$fa-pull-margin         : .3em;

$fa-primary-opacity     : 1;
$fa-secondary-opacity   : .4;

$fa-stack-vertical-align: middle;
$fa-stack-width         : ($fa-fw-width * 2);
$fa-stack-z-index       : auto;


$fa-font-path           : "/Webartchitecte/fonts/fontawesome";




@import './node_modules/@fortawesome/fontawesome-pro/scss/variables';
@import './node_modules/@fortawesome/fontawesome-pro/scss/mixins';
//@import './node_modules/@fortawesome/fontawesome-pro/scss/regular';

:root, :host {
  --#{$fa-css-prefix}-style-family-classic: "#{$fa-style-family}";
  --#{$fa-css-prefix}-font-regular:normal 400 1em/1 "#{$fa-style-family}";
  --#{$fa-css-prefix}-font-light: normal 300 1em/1  "#{$fa-style-family-light}";
  --#{$fa-css-prefix}-style-family-brands: "#{$fa-style-family-brands}";
  --#{$fa-css-prefix}-font-brands: normal 400 1em/1 "#{$fa-style-family-brands}";
  --#{$fa-css-prefix}-style-family-duotone: "#{$fa-style-family-duotone}";
  --#{$fa-css-prefix}-font-duotone: normal 900 1em/1 "#{$fa-style-family-duotone}";
  --#{$fa-css-prefix}-font-weight: 900;
  --#{$fa-css-prefix}-font-family: "#{$fa-style-family}";
  // --fa-font-family: "Font Awesome 6 Pro";
  // --fa-font-weight: 900;
  
}
@font-face {
  font-display:block;
  font-family:"#{$fa-style-family}";
  font-style:normal;
  font-weight:400;
  line-height: inherit;
  src:url(/Webartchitecte/fonts/fontawesome/fa-regular-400.woff2) format("woff2"),
  url(/Webartchitecte/fonts/fontawesome/fa-regular-400.ttf) format("truetype")
}



.#{$fa-css-prefix}-regular,
.far {
  font-family: var(--#{$fa-css-prefix}-font-regular);
  font-weight:400
}
@font-face {
  font-family: "#{$fa-style-family-light}";
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-light-300.ttf') format('truetype');
}
.fal,
.#{$fa-css-prefix}-light {
  font-family: var(--#{$fa-css-prefix}-font-light);
  font-weight: 300;
}



//--
@font-face {
  font-family: "#{$fa-style-family-brands}";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype');
}
@font-face {
  font-family: "#{$fa-style-family-duotone}";
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-duotone-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-duotone-900.ttf') format('truetype');
}


//core
@import './node_modules/@fortawesome/fontawesome-pro/scss/core';
@import './node_modules/@fortawesome/fontawesome-pro/scss/sizing';
@import './node_modules/@fortawesome/fontawesome-pro/scss/fixed-width';
@import './node_modules/@fortawesome/fontawesome-pro/scss/list';
@import './node_modules/@fortawesome/fontawesome-pro/scss/bordered-pulled';
@import './node_modules/@fortawesome/fontawesome-pro/scss/animated';
@import './node_modules/@fortawesome/fontawesome-pro/scss/rotated-flipped';
@import './node_modules/@fortawesome/fontawesome-pro/scss/stacked';
//marque
@import './node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import './node_modules/@fortawesome/fontawesome-pro/scss/duotone';
@import './node_modules/@fortawesome/fontawesome-pro/scss/icons';
@import './node_modules/@fortawesome/fontawesome-pro/scss/screen-reader';
@import './node_modules/@fortawesome/fontawesome-pro/scss/v4-shims';
//solid
@import './node_modules/@fortawesome/fontawesome-pro/scss/solid';

.icon_fa{
  line-height: inherit;
}


.icone-wa-side{
	display: flex;
	flex-direction: row;
	align-items: start;
	grid-column-gap: calc(var(--grille--gap,2rem)/2);
	grid-row-gap: calc(var(--grille--gap,2rem)/2);
	
	span.icon_fa {
	  flex: 0 0 auto; // Ne grandit pas, ne rétrécit pas, avec une base auto
	}
	
	// Sélectionnez tous les éléments directs sauf span.icon_fa
	> :not(span.icon_fa):not(br) {
	  flex: 1; // Grandit pour prendre le reste de l'espace
	}
  }

  [data-style-align="right"]{	
	&.icone-wa-side{
		flex-direction:row-reverse;
		justify-content: flex-start;
	}	
}
//center
[data-style-align="center"]{	
  &.icone-wa-side{
    flex-direction:column;
    justify-content: center;
    align-items: center;
  }	
}